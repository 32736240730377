<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <v-text-field
            :error-messages="nameErrors"
            :label="$t('name')"
            @blur="$v.item.name.$touch()"
            @input="$v.item.name.$touch()"
            required
            v-model="item.name"
          />
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <v-select
            :error-messages="rolesErrors"
            :label="$t('roles')"
            @blur="$v.item.roles.$touch()"
            @input="$v.item.roles.$touch()"
            required
            multiple
            chips
            :items="availableRoles"
            v-model="item.roles"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';

export default {
  name: 'TenantGroupForm',
  mixins: [validationMixin],
  props: {
    values: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => {
      }
    },
    initialValues: {
      type: Object,
      default: () => {
      }
    }
  },
  mounted() {
  },
  data() {
    return {
      name: null,
      roles: null,
      availableRoles: [
        'ROLE_TENANT_OWNER',
        'ROLE_SEMINAR_TECHNICIAN',
        'ROLE_ADMIN',
      ]
    };
  },
  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    nameErrors() {
      const errors = [];

      if (!this.$v.item.name.$dirty) {
        return errors;
      }

      has(this.violations, 'name') && errors.push(this.violations.name);

      !this.$v.item.name.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    rolesErrors() {
      const errors = [];

      if (!this.$v.item.roles.$dirty) {
        return errors;
      }

      has(this.violations, 'roles') && errors.push(this.violations.roles);

      return errors;
    },
    violations() {
      return this.errors || {};
    }
  },
  methods: {},
  validations: {
    item: {
      name: {
        required,
      },
      roles: {},
    }
  }
}
</script>
